import React from 'react';

const SecretPage = () => {
    return (
        <div>
            <h2>THIS IS A SECRET</h2>
        </div>
    );
};

export default SecretPage;
